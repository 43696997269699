import { appRating, appReview } from '../../../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { PushNotifications, Token, RegistrationError, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Preferences } from '@capacitor/preferences';
import { AppLauncher } from '@capacitor/app-launcher';
import { Clipboard } from '@capacitor/clipboard';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { DOCUMENT, Location } from '@angular/common';
import { RateApp } from 'capacitor-rate-app';
import { Router } from '@angular/router';
import { FirebaseService } from '..';

@Injectable({
  providedIn: 'root'
})
export class CapacitorService {

  //----------------------------------------------------------------------------
  // Global
  //----------------------------------------------------------------------------

  public authorization: string = '';
  public appearance: string = '';
  public scheme: string = '';
  public language: string = '';
  public platform: string = '';
  public push: string = '';
  public native: boolean = false;
  public rating: boolean = false;
  public tour: boolean = false;
  public map: boolean = false;
  public goals: boolean = false;
  public display: boolean = false;
  public lock: boolean = false;
  public dark: boolean = false;
  public desktop: boolean = false;
  public mobile: boolean = false;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public location: Location,
    public router: Router,
    public plt: Platform
  ) { }

  //----------------------------------------------------------------------------
  // Initialize Application
  //----------------------------------------------------------------------------

  async initApplication() {
    
    // Global
    this.rating = await this.getStorage(appRating) ? false : true;
    this.goals = await this.getStorage('Goals') ? true : false;
    this.tour = await this.getStorage('TourCreate') ? false : true;
    this.map = await this.getStorage('TourPlaces') ? false : true;
    this.display = await this.getStorage('Display') ? true : false;
    this.lock = await this.getStorage('Lock') ? true : false;
    this.language = await this.getStorage('Language') ?? '';
    this.push = await this.getStorage('Push') ?? '';
    this.platform = await this.getPlatform();
    this.native = await this.isNative();
    this.desktop = this.plt.is('desktop');
    this.mobile = this.plt.is('capacitor');
  }

  //----------------------------------------------------------------------------
  // Initalize Appearance
  //----------------------------------------------------------------------------

  async initAppearance() {
    let scheme = window.matchMedia("(prefers-color-scheme: dark)");
    scheme.addEventListener('change', (e) => this.checkAppearance(e.matches));
    this.checkAppearance(scheme.matches);
  }

  //----------------------------------------------------------------------------
  // Get Appearance
  //----------------------------------------------------------------------------
  
  async getAppearance() {
    return await this.getStorage('Appearance') ?? 'system';
  }

  //----------------------------------------------------------------------------
  // Set Appearance
  //----------------------------------------------------------------------------

  async setAppearance(scheme: string) {
    if (scheme != 'system') await this.setStorage('Appearance', scheme);
    if (scheme == 'system') await this.removeStorage('Appearance');
    this.initAppearance();
    this.checkAppearance();
  }

  //----------------------------------------------------------------------------
  // Check Appearance
  //----------------------------------------------------------------------------

  async checkAppearance(toggle: boolean = false) {
    let override = await this.getStorage('Appearance');
    this.scheme = override ? override : toggle ? 'dark' : 'light';
    document.documentElement.classList.toggle(
      'ion-palette-dark', this.scheme == 'dark' ? true : false
    );
  }

  //----------------------------------------------------------------------------
  // Get Language
  //----------------------------------------------------------------------------

  async getLanguage(selector?: string) {
    let language = 'en';

    // Device
    const device = await Device.getLanguageCode();
    if (device.value) language = device.value;

    // Storage
    if (this.language) language = this.language;

    // Browser
    const paths = ['en', 'fr', 'de', 'it', 'nl', 'pt', 'es'];
    const route = location.pathname.substring(1);
    if (paths.indexOf(route) >= 0) language = route;
    language = paths.includes(language) ? language : 'en';

    // Override
    if (selector) language = selector;

    // Set
    this.setStorage('Language', language);
    this.document.documentElement.lang = language;
    this.translate.setDefaultLang(language);
    this.translate.use(language);

    // Return
    this.language = language;
    return language;
  }

  //----------------------------------------------------------------------------
  // Get Authorization
  //----------------------------------------------------------------------------

  async getAuthorization() {
    return await SecureStoragePlugin.get({ key: 'Authorization' })
      .then(success => { return success['value']; })
      .catch(error => { return ''; });
  }

  //----------------------------------------------------------------------------
  // Check Authorization
  //----------------------------------------------------------------------------

  async checkAuthorization() {
    return await SecureStoragePlugin.get({ key: 'Authorization' })
      .then(success => { return true; })
      .catch(error => { return false; });
  }

  //----------------------------------------------------------------------------
  // Set Keychain
  //----------------------------------------------------------------------------

  async setAuthorization(value: string) {
    return await SecureStoragePlugin.set({
      key: 'Authorization',
      value: value
    });
  }

  //----------------------------------------------------------------------------
  // Is Native
  //----------------------------------------------------------------------------

  async isNative() {
    return Capacitor.isNativePlatform();
  }

  //----------------------------------------------------------------------------
  // Get Platform
  //----------------------------------------------------------------------------

  async getPlatform() {
    return Capacitor.getPlatform();
  }

  //----------------------------------------------------------------------------
  // Get Info
  //----------------------------------------------------------------------------

  async getInfo() {
    return await Device.getInfo();
  }

  //----------------------------------------------------------------------------
  // Get Photos
  //----------------------------------------------------------------------------

  async getPhotos() {
    if (await Camera.checkPermissions()) {
      return await Camera.getPhoto({
        quality: 100,
        width: 1080,
        source: CameraSource.Photos,
        allowEditing: false,
        saveToGallery: true,
        resultType: CameraResultType.Uri
      });
    } else {
      return await Camera.requestPermissions();
    }
  }

  //----------------------------------------------------------------------------
  // Start Haptic
  //----------------------------------------------------------------------------

  async startHaptic() {
    if (Capacitor.isNativePlatform()) {
      return await Haptics.impact({
        style: ImpactStyle.Light
      });
    }
  }

  //----------------------------------------------------------------------------
  // Open Browser
  //----------------------------------------------------------------------------

  async openBrowser(url: string) {
    return await Browser.open({
      url: url.startsWith('http') ? url : 'https://' + url
    });
  }

  //----------------------------------------------------------------------------
  // Open Launcher
  //----------------------------------------------------------------------------

  async openLauncher(app: string,  url: string) {
    const { value } = await AppLauncher.canOpenUrl({ url: app });
    if (await this.isNative() && value) {
      await AppLauncher.openUrl({ url: app });
    } else {
      await this.openBrowser(url);
    }
  }

  //----------------------------------------------------------------------------
  // Open Share
  //----------------------------------------------------------------------------

  async openShare(url: string, text: string) {
    return await Share.share({
      title: 'iBucket.app',
      text: text,
      url: url,
      dialogTitle: 'Share'
    });
  }

  //----------------------------------------------------------------------------
  // Set Clipboard
  //----------------------------------------------------------------------------

  async setClipboard(url: string, text: string) {
    return await Clipboard.write({
      string: text + ' ' + url
    });
  }

  //----------------------------------------------------------------------------
  // Get Storage
  //----------------------------------------------------------------------------

  async getStorage(key: string) {
    await Preferences.configure({ group: 'Storage' });
    const data: any = await Preferences.get({ key: key });
    return data['value'];
  }

  //----------------------------------------------------------------------------
  // Set Storage
  //----------------------------------------------------------------------------

  async setStorage(key: string, value: string) {
    await Preferences.configure({ group: 'Storage' });
    await Preferences.set({ key: key, value: value });
    return true;
  }

  //----------------------------------------------------------------------------
  // Remove Storage
  //----------------------------------------------------------------------------

  async removeStorage(key: string) {
    await Preferences.configure({ group: 'Storage' });
    await Preferences.remove({ key: key });
    return true;
  }

  //----------------------------------------------------------------------------
  // Clear Storage
  //----------------------------------------------------------------------------

  async clearStorage() {
    await SecureStoragePlugin.clear();
    await Preferences.configure({ group: 'Storage' });
    await Preferences.clear();
    return true;
  }

  //----------------------------------------------------------------------------
  // Request Review
  //----------------------------------------------------------------------------

  async requestReview(reviews: string) {
    if (!await this.getStorage(appReview) && reviews) {
      this.firebase.setEvent('review_request');
      await this.setStorage(appReview, 'hide');
      RateApp.requestReview();
    }
  }

  //----------------------------------------------------------------------------
  // Initialize Notifications
  //----------------------------------------------------------------------------

  async initNotifications() {
    this.registerNotifications();
    this.openNotifications();
    this.clearNotifications();
  }

  //----------------------------------------------------------------------------
  // Get Token
  //----------------------------------------------------------------------------

  async getToken() {
    if (Capacitor.isNativePlatform()) {
      await this.registerNotifications();
      return await this.getStorage('Push');
    }
    return '';
  };

  //----------------------------------------------------------------------------
  // Local Notification
  //----------------------------------------------------------------------------

  async localNotification(date: any, title: string, body: string) {
    this.firebase.setEvent('notification_local');

    // Schedule
    return await LocalNotifications.schedule({
      notifications: [{
        title: title,
        body: body,
        id: new Date().getTime(),
        schedule: { at: new Date(date.replace(/\s/g, 'T')) }
      }]
    });
  }

  //----------------------------------------------------------------------------
  // Register Notifications
  //----------------------------------------------------------------------------

  async registerNotifications() {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive) PushNotifications.register();
      });
      PushNotifications.addListener('registration',
        (token: Token) => {
          this.setStorage('Push', token.value);
          this.push = token.value;
          this.firebase.setEvent('notification_setup');
        }
      );
      PushNotifications.addListener('registrationError',
        (error: RegistrationError) => {
          this.firebase.setEvent('notification_error');
        }
      );
    }
  }

  //----------------------------------------------------------------------------
  // Open Notifications
  //----------------------------------------------------------------------------

  async openNotifications() {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          this.firebase.setEvent('notification_received');
        }
      );
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          this.firebase.setEvent('notification_action');
        }
      );
    }
  }

  //----------------------------------------------------------------------------
  // Clear Notifications
  //----------------------------------------------------------------------------

  async clearNotifications() {
    if (Capacitor.isNativePlatform()) {
      this.removeStorage('Notifications');
      PushNotifications.removeAllDeliveredNotifications();
    }
  }
}
